import { fetchWrapper } from '_helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONTEXT_PATH}/hires`;

export const hireService = {
    search,
};

function search(params) {
    return fetchWrapper.get(`${baseUrl}${params}`);
}
